import "./App.css";
import TaskApp from "./components/TaskApp";

function App() {
  return (
    <>
      <TaskApp />
    </>
  );
}

export default App;
