import React from "react";
import TaskItem from "./TaskItem";

export default function TaskList({ tasks, setTasks }) {
  return (
    <>
      {tasks.map((task, key) => {
        return (
          <TaskItem
            message={task.message}
            id={task.id}
            setTasks={setTasks}
            tasks={tasks}
            key={key}
          />
        );
      })}
    </>
  );
}
