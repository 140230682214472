import React, { useState } from "react";
import Header from "./Header";
import InfoBar from "./InfoBar";
import TaskAdder from "./TaskAdder";
import TaskList from "./TaskList";
import "../App.css";

export default function TaskApp() {
  const [tasks, setTasks] = useState([]);
  console.log(tasks, "tasks");
  return (
    <div className="container">
      <div className="components">
        <Header />
        <InfoBar taskTotal={tasks.length} />
        <TaskAdder setTasks={setTasks} tasks={tasks} />
        <TaskList tasks={tasks} setTasks={setTasks} />
      </div>
    </div>
  );
}
