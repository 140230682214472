import React from "react";
import Button from "@mui/material/Button";
import DeleteIcon from "@mui/icons-material/Delete";
import "../App.css";

export default function TaskItem({ message, id, setTasks, tasks }) {
  const handleDelete = () => {
    const updatedTask = tasks.filter((task) => task.id !== id);
    setTasks(updatedTask);
  };
  return (
    <div className="container-task">
      <div className="task">
        <div style={{ display: "inline-block" }}>{message}</div>
        <Button
          variant="contained"
          color="error"
          onClick={() => handleDelete()}
          startIcon={<DeleteIcon />}
        >
          Hapus
        </Button>
      </div>
    </div>
  );
}
