import React, { useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import "../App.css";

export default function TaskAdder({ setTasks, tasks }) {
  const [currentValue, setCurrentValue] = useState("");
  const handleAddTask = () => {
    const newTask = {
      id: tasks.length + 1,
      message: currentValue,
    };
    setTasks([...tasks, newTask]);
    setCurrentValue("");
  };
  return (
    <div className="task-adder">
      <div className="TextField-without-border-radius">
        <TextField
          value={currentValue}
          onChange={(event) => setCurrentValue(event.target.value)}
          label="Tambah Task"
          variant="outlined"
        />
      </div>
      <Button
        className="button-radius"
        variant="contained"
        color="primary"
        disabled={currentValue === ""}
        onClick={() => handleAddTask()}
      >
        Tambah
      </Button>
    </div>
  );
}
